<template>
    <canvas v-show="numbers && numbers.length > 0" ref="canvas"></canvas>
</template>
<script>
export default {
    name: 'half-circle-plot',
    props: ['numbers', 'height', 'width', 'theme'],
    data () {
        return {
            curPercent: 0,
            maxPercent: 0,
            drawInterval: null
        }
    },
    computed: {
        colors () {
            if (this.theme === 'green') return ['#4ade80', '#15803d', '#6D9A7A', '#374E3E', '#72A281']
            if (this.theme === 'blue') return ['#11538C', '#139DF2', '#0FB2F2', '#1BCBF2', '#5CF2E3']
            return ['#374151', '#ffffff', '#C98908', '#C88F25', '#DBA521']
        },
        screenWidth () {
            return window.innerWidth - 40
        }
    },
    methods: {
        drawArc (ctx, startAngle, endAngle, color) {
            ctx.beginPath()
            ctx.arc(this.screenWidth / 2, this.height - 32, this.height - 40, startAngle, endAngle)
            ctx.strokeStyle = color
            ctx.lineCap = 'round'
            ctx.lineWidth = this.screenWidth / (this.screenWidth * 0.07)
            ctx.stroke()
            ctx.closePath()
        },
        calculatePercent (max, current) {
            var percent = current * 110 / max
            return percent
        },
        degToRad (deg) {
            return deg / 100 * Math.PI
        },
        drawBg (ctx) {
            // var c = document.getElementById('canvas')
            var startAngle = 0.95 * Math.PI
            var endAngle = 2.05 * Math.PI

            this.drawArc(ctx, startAngle, endAngle, this.colors[1])
        },
        drawData (ctx, percent) {
            // var c = document.getElementById('canvas')
            var startAngle = 0.95 * Math.PI
            var colorsIndex = 0

            var endAngle = this.degToRad(percent)
            this.drawArc(ctx, startAngle, startAngle + endAngle, this.colors[colorsIndex])
        },
        draw () {
            var c = this.$refs.canvas
            var ctx = c.getContext('2d')
            ctx.save()
            ctx.clearRect(0, 0, this.screenWidth, this.height)
            this.drawBg(ctx)
            this.drawData(ctx, this.curPercent)
            ctx.restore()
        },
        init () {
            const ratio = Math.ceil(window.devicePixelRatio)
            const canvas = this.$refs.canvas
            canvas.width = this.screenWidth * ratio
            canvas.height = this.height * ratio
            canvas.style.width = `${this.screenWidth}px`
            canvas.style.height = `${this.height}px`
            canvas.getContext('2d').setTransform(ratio, 0, 0, ratio, 0, 0)

            // if (this.numbers[0] === 0) return
            this.maxPercent = this.calculatePercent(this.numbers[1], this.numbers[0])
            this.curPercent = 0
            this.drawInterval = setInterval(() => {
                if (this.curPercent < this.maxPercent) this.curPercent++
                if (this.curPercent >= this.maxPercent) clearInterval(this.drawInterval)
                this.draw()
            }, 1)
        }
    },
    mounted () {
        this.init()
    },
    watch: {
        numbers () {
            clearInterval(this.drawInterval)
            this.init()
        }
    }
}
</script>
