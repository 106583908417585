<template>
    <div class="container mx-auto px-4 md:px-0 py-4">
        <div class="flex flex-row items-center justify-between w-full pl-2 py-2">
            <div class="flex flex-row items-center justify-between w-11/12">
                <div class="flex flex-col">
                    <div class="text-2xl font-semibold" @click="chart_maximized = !chart_maximized">Сводка</div>
                    <div class="text-xs text-gray-500">{{ date_from_local  }} - {{ date_to_local }}</div>
                </div>
                <div class="flex flex-col justify-center px-2" @click="menu_open = !menu_open">
                    <div class="text-2xl font-semibold">
                        <animated-number :value="net_balance_diff" :is-public="isPublicIncomes && isPublicExpenses" />
                    </div>
                    <div class="text-end text-xs text-gray-500">
                        <animated-number :value="net_balance_percent" :decimals=2  :is-public="true"/>%
                    </div>
                </div>
            </div>
            <div>
                <div class="flex flex-row items-center justify-end w-12">
                    <div class="bg-gray-200 rounded-md p-2 active:bg-gray-500" @click="menu_open = !menu_open">
                        <img src="https://img.icons8.com/?size=256&id=21622&format=png" class="h-6 w-6"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div v-if="date_filter_open" class="flex flex-row justify-around items-center py-4">
            с <input v-model="date_from" @change="getData()" type="date" class="bg-white p-2 rounded-md w-2/5" />
            до <input v-model="date_to" @change="getData()" type="date" class="bg-white p-2 rounded-md w-2/5" />
        </div> -->
        <div class="fixed top-0 left-0 z-[80] w-full h-full overflow-hidden transition-all flex justify-end" :class="[ !menu_open ? 'translate-x-full': null ]">
            <div class="w-10/12  bg-white px-4 md:px-0 py-4 h-full">
                <div class="flex flex-row items-center justify-between w-full px-2 py-2">
                    <div class="text-lg font-semibold">Сводка</div>
                    <div class="bg-gray-200 hover:bg-gray-300 rounded-full p-2" @click="menu_open = !menu_open">
                        <img src="https://img.icons8.com/?size=512&id=8112&format=png" class="h-6 w-6"/>
                    </div>
                </div>
                <div>
                    <router-link :to="{name: 'expenses_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Список расходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'expenses_tags_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Категории расходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'expenses_create'}">
                        <div class="border-b-2 py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">➕ Добавить в расходы</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'incomes_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Список доходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'incomes_tags_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Категории доходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'incomes_create'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">➕ Добавить в доходы</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'piggybanks'}">
                        <div class="border-b-2 py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Копилки</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'stashes'}">
                        <div class="border-b-2 py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Долги</div>
                        </div>
                    </router-link>
                    <div class="flex items-center py-2 my-4 px-4">
                        <input type="checkbox" v-model="isPublicIncomes" class="h-8 w-8 mr-2" /> Показать доходы
                    </div>
                    <div class="flex items-center py-2 my-4 px-4">
                        <input type="checkbox" v-model="isPublicExpenses" class="h-8 w-8 mr-2" /> Показать расходы
                    </div>
                </div>
            </div>
        </div>
        <div v-show="is_loaded" class="container h-full pb-16">
            <div class="flex bg-white rounded-md px-4 py-2 my-4 justify-between overflow-x-auto transition-all" id="chartWrap"
                :class="[ chart_maximized ? 'h-48' : 'h-32']">
                <div v-for="point,index in dataYear" :key="index" class="h-full flex flex-col items-center justify-center mx-1">
                    <div class="h-full flex items-end relative"
                        :class="[ selected_month_index !== null ? selected_month_index !== index ? 'opacity-50 w-6' : 'opacity-100 w-6' : 'opacity-100 w-6' ]">
                        <div class="w-5 rounded-t-sm bg-green-400 transition-all delay-350 border-t flex items-end" style="min-height: 1%"
                            :style="{ 'height': parseFloat((point.incomes.summ.raw / dataYearMeta.max) * 100).toFixed(2) +  '%' }">
                            <div :class="[selected_month_index === index ? 'h-full' : 'h-0']" class="w-full transition-all">
                                <div class="w-full bg-green-300"
                                    :style="{ 'height': parseFloat(point.incomes.summ.types[0][0] * 100 / point.incomes.summ.raw).toFixed(2) + '%' }"></div>
                                <div class="w-full bg-green-400"
                                    :style="{ 'height': parseFloat(point.incomes.summ.types[1][0] * 100 / point.incomes.summ.raw).toFixed(2) + '%' }"></div>
                                <div class="w-full bg-green-500"
                                    :style="{ 'height': parseFloat(point.incomes.summ.types[0][1] * 100 / point.incomes.summ.raw).toFixed(2) + '%' }"></div>
                                <div class="w-full bg-green-600"
                                    :style="{ 'height': parseFloat(point.incomes.summ.types[1][1] * 100 / point.incomes.summ.raw).toFixed(2) + '%' }"></div>
                            </div>
                        </div>
                        <div class="w-5 rounded-t-sm bg-amber-200 transition-alll delay-350 border-t flex items-end" style="min-height: 1%"
                            :style="{ 'height': parseFloat((point.expenses.summ.raw / dataYearMeta.max) * 100).toFixed(2) +  '%' }">
                            <div :class="[selected_month_index === index ? 'h-full' : 'h-0']" class="w-full transition-all">
                                <div class="w-full bg-amber-300"
                                    :style="{ 'height': parseFloat(point.expenses.summ.types[0] * 100 / point.expenses.summ.raw).toFixed(2) + '%' }"></div>
                                <div class="w-full bg-amber-600"
                                    :style="{ 'height': parseFloat(point.expenses.summ.types[1] * 100 / point.expenses.summ.raw).toFixed(2) + '%' }"></div>
                            </div>
                        </div>
                        <!-- {{ point.expenses.summ }} -->
                    </div>
                    <div class="text-sm p-1 rounded-sm cursor-pointer w-full text-center"
                        :class="[ point.incomes.summ.raw > point.expenses.summ.raw ? 'bg-green-100 text-green-700' : 'bg-rose-100 text-rose-700']"
                        @click="setDate(point.meta.month, point.meta.year, point.meta.month_last_day, index)">
                        {{ point.meta.month }}
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap">
                <div class="w-full xl:w-1/2 xl:pl-2">
                    <div v-if="incomes != null && incomes.tags.length > 0" class="my-4">
                        <div class="p-4 font-semibold flex justify-between text-lg">
                            <div class="flex items-center">
                                <div>
                                    <router-link :to="{ name: 'incomes_list', params: { 'date_from': date_from, 'date_to': date_to }}">
                                        Доходы
                                    </router-link>
                                </div>
                                <router-link :to="{ name: 'incomes_create'}" class="ml-2">
                                    <div class="flex h-6 w-6 rounded-md bg-gray-200 text-gray-700 active:bg-gray-300 justify-center items-center leading-5">+</div>
                                </router-link>
                            </div>
                            <div>
                                + <animated-number :value="incomes.summ.raw" :is-public="isPublicIncomes" />
                            </div>
                        </div>
                        <div @click="show_full_incomes = !show_full_incomes" class="flex flex-row justify-around">
                            <div class="w-full mb-2 py-4 bg-white rounded-md flex flex-row justify-around items-center">
                                <div class="w-1/2 flex justify-center">
                                    <circle-plot :data="incomes_circle_plot" :height="plotHeight" theme="green"/>
                                </div>
                                <div class="pl-2 w-2/3 pr-4">
                                    <div class="w-full">
                                        <div class="text-sm xl:text-md">пассивно:</div>
                                        <div class="text-sm xl:text-xl w-full flex justify-between" style="color: #4ade80">
                                            <div>{{ (incomes_circle_plot[0] * 100 / (incomes_circle_plot[0] + incomes_circle_plot[1])).toFixed(2) }}%</div>
                                            <!-- <div>{{ new Intl.NumberFormat('fr-FR').format(incomes_circle_plot[0]) }}</div> -->
                                            <div>
                                                <animated-number :value="incomes_circle_plot[0]" :is-public="isPublicIncomes" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="xl:pt-2">
                                        <div class="text-sm xl:text-md">остальное:</div>
                                        <div class="text-sm xl:text-xl w-full flex justify-between" style="color: #15803d">
                                            <div>{{ (incomes_circle_plot[1] * 100 / (incomes_circle_plot[0] + incomes_circle_plot[1])).toFixed(2) }}%</div>
                                            <!-- <div>{{ new Intl.NumberFormat('fr-FR').format(incomes_circle_plot[1]) }}</div> -->
                                            <div>
                                                <animated-number :value="incomes_circle_plot[1]" :is-public="isPublicIncomes"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-show="show_full_incomes" class="bg-white rounded-md px-4 pt-4 pb-2 text-xs">
                            <div v-for="tag in incomes.tags" :key="tag.id" class="flex py-1 items-center transition-all"
                                :class="[ tag.is_passive === '1' && tag.is_recursive === '1' ? 'text-green-700' : 'text-black']">
                                <div class="w-4/5">
                                    {{ tag.emoji }}
                                    {{ tag.title }}
                                </div>
                                <div class="w-3/5 pr-0 rounded-full flex justify-end bg-gray-50">
                                    <div class="w-0 h-2 rounded-full transition-all"
                                    :class="[tag.is_passive === '1' && tag.is_recursive === '1' ? 'bg-green-400' : 'bg-green-200']"
                                    :style="{ width: (parseInt(tag.summ.replaceAll(' ','')) * 100) / incomes.max + '%'}">
                                    </div>
                                </div>
                                <div class="w-2/5 flex justify-between">
                                    <!-- <div class="text-center text-gray-300 w-2/6">
                                        {{ tag.amount }}
                                    </div> -->
                                    <div class="text-right w-11/12 text-xs" @click="()=> setSelectedIncome(tag)">
                                        <!-- {{ tag.summ }} -->
                                        <animated-number :value="tag.summ" :is-public="isPublicIncomes"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="selected_income != null" class="flex flex-col justify-center bg-white rounded-md p-4 text-xs mt-2">
                            <div class="mb-4 font-semibold text-lg">
                                {{ selected_income.emoji }}
                                {{ selected_income.title }}
                                {{ selected_income.summ }}
                            </div>
                            <div class="flex justify-center w-full overflow-x-auto" id="incomesChartWrap">
                                <!-- {{ selected_income_data }} -->
                                <graph-plot :data="selected_income_data" height="180" width="360" theme="blue" class="bg-white rounded-md my-4"/>
                            </div>
                        </div>
                    </div>
                    <div v-else class="flex flex-col justify-center items-center text-center py-4 text-xs text-gray-500  w-full xl:w-1/2">
                        <i class="pb-2">Нет информации по доходам</i>
                        <router-link :to="{ name: 'incomes_create'}">
                            <div class="py-2 px-6 rounded-full bg-gray-300 text-gray-500">
                                + добавить
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class=" w-full xl:w-1/2 xl:pl-2">
                    <div v-if="expenses != null && expenses.tags.length > 0" class="my-4">
                        <div class="p-4 font-semibold flex justify-between text-lg">
                            <div class="flex items-center">
                                <div>
                                    <router-link :to="{ name: 'expenses_list', params: { 'date_from': date_from, 'date_to': date_to }}">
                                        Расходы
                                    </router-link>
                                </div>
                                <router-link :to="{ name: 'expenses_create'}" class="ml-2">
                                    <div class="flex h-6 w-6 rounded-md bg-gray-200 text-gray-700 active:bg-gray-300 justify-center items-center leading-5">+</div>
                                </router-link>
                            </div>
                            <div class="flex flex-row">
                                - <animated-number :value="expenses.summ.raw" :is-public="isPublicExpenses" />
                            </div>
                        </div>
                        <div @click="show_full_expenses = !show_full_expenses" class="flex flex-row justify-around">
                            <div class="w-full mb-2 py-4 bg-white rounded-md flex flex-row justify-between items-center">
                                <div class="w-1/2 flex justify-center">
                                    <circle-plot :data="expenses_circle_plot" :height="plotHeight" class=""/>
                                </div>
                                <div class="pl-2 w-2/3 pr-4">
                                    <div class="w-full">
                                        <div class="text-sm xl:text-md">операционка:</div>
                                        <div class="text-sm xl:text-xl w-full flex justify-between" style="color: #F2C752">
                                            <div>{{ (expenses_circle_plot[0] * 100 / (expenses_circle_plot[0] + expenses_circle_plot[1])).toFixed(2) }}%</div>
                                            <!-- <div>{{ new Intl.NumberFormat('fr-FR').format(expenses_circle_plot[0]) }}</div> -->
                                            <div><animated-number :value="expenses_circle_plot[0]" :is-public="isPublicExpenses"/></div>
                                        </div>
                                    </div>
                                    <div class="xl:pt-2">
                                        <div class="text-sm xl:text-md">остальное:</div>
                                        <div class="text-sm xl:text-xl  w-full flex justify-between" style="color: #F28705">
                                            <div>{{ (expenses_circle_plot[1] * 100 / (expenses_circle_plot[0] + expenses_circle_plot[1])).toFixed(2) }}%</div>
                                            <!-- <div>{{ new Intl.NumberFormat('fr-FR').format(expenses_circle_plot[1]) }}</div> -->
                                            <div><animated-number :value="expenses_circle_plot[1]" :is-public="isPublicExpenses" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-show="show_full_expenses" class="bg-white rounded-md p-4 text-xs">
                            <div v-for="tag in expenses.tags" :key="tag.id" class="flex py-1 items-center transition-all"
                                :class="[ tag.is_operational === '1' ? 'text-amber-600' : 'text-black']">
                                <div class="w-4/5">
                                    {{ tag.emoji }}
                                    {{ tag.title }}
                                </div>
                                <div class="w-3/5 pr-0 rounded-full flex justify-end bg-gray-50">
                                    <div class="w-0 h-2 rounded-full transition-all" :style="{ width: (parseInt(tag.summ.replaceAll(' ','')) * 100) / expenses.max + '%'}"
                                    :class="[ tag.is_operational === '1' ? 'bg-amber-300' : 'bg-rose-200 ']">
                                    </div>
                                </div>
                                <div class="w-2/5 flex justify-between">
                                    <!-- <div class="text-center text-gray-300 w-2/6">
                                        {{ tag.amount }}
                                    </div> -->
                                    <div class="hidden xl:flex justify-center w-11/12">
                                        <animated-number :value="tag.amount" :is-public="isPublicExpenses"/>
                                    </div>
                                    <!-- <div class="hidden xl:flex justify-center w-11/12">
                                        <animated-number :value="tag.summ / tag.amount" :is-public="isPublicExpenses"/>
                                    </div> -->
                                    <div class="text-right w-11/12 text-xs" @click="()=> setSelectedExpense(tag)">
                                        <!-- {{ tag.summ }} -->
                                        <animated-number :value="tag.summ" :is-public="isPublicExpenses"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="selected_expense != null" class="flex flex-col justify-center bg-white rounded-md p-4 text-xs mt-2">
                            <div class="mb-4 font-semibold text-lg">
                                {{ selected_expense.emoji }}
                                {{ selected_expense.title }}
                                {{ selected_expense.summ }}
                            </div>
                            <div class="w-full overflow-x-auto" id="expensesChartWrap">
                                <graph-plot :data="selected_expense_data" height="180" width="1080" class="bg-white rounded-md my-4"/>
                            </div>
                        </div>
                        <!-- {{ expenses }} -->
                    </div>
                    <div v-else class="flex flex-col justify-center items-center text-center py-4 text-xs text-gray-500  w-full xl:w-1/2">
                        <i class="pb-2">Нет информации по расходам</i>
                        <router-link :to="{ name: 'expenses_create'}">
                            <div class="py-2 px-6 rounded-full bg-gray-300 text-gray-500">
                                + добавить
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CirclePlot from '@/components/CirclePlot.vue'
import GraphPlot from '@/components/GraphPlot.vue'
import AnimatedNumber from '@/components/AnimatedNumber.vue'

export default {
    components: {
        CirclePlot,
        GraphPlot,
        AnimatedNumber
    },
    data () {
        return {
            isPublicIncomes: true,
            isPublicExpenses: true,
            show_full_incomes: false,
            show_full_expenses: true,
            incomes: null,
            expenses: null,
            meta: null,
            menu_open: false,
            selected_month_index: null,
            date_filter_open: false,
            date_from: null,
            date_to: null,
            is_loaded: false,
            cdate: null,
            dataYear: null,
            dataYearMeta: null,
            chart_maximized: false,
            selected_income: null,
            selected_income_data: null,
            selected_expense: null,
            selected_expense_data: null,
            windowWidth: window.innerWidth
        }
    },
    methods: {
        setDate (month, year, monthLastDay, index) {
            this.date_from = year + '-' + month + '-01'
            this.date_to = year + '-' + month + '-' + monthLastDay
            this.getData()

            if (this.selected_month_index !== index) this.selected_month_index = index; else this.selected_month_index = null
        },
        getData () {
            this.selected_income = null
            this.selected_expense = null
            const response = this.getServerData('get_dash', ['all', this.date_from, this.date_to])
            response.then(response => {
                                this.incomes = response.data.incomes
                                this.expenses = response.data.expenses
                                // this.meta = response.data.meta
                                console.log(this.expenses)
                            })
                            .catch(function (error) {
                                // handle error
                                console.log(error)
                            })
                            .finally(() => (this.is_loaded = true))
        },
        getDataYear () {
            const response = this.getServerData('get_dash_year', ['all', this.date_from, this.date_to])
            response.then(response => {
                                this.dataYear = response.data.data
                                this.dataYearMeta = response.data.meta
                                // this.meta = response.data.meta
                            })
                            .catch(function (error) {
                                // handle error
                                console.log(error)
                            })
                            .finally(() => {
                                this.is_loaded = true
                                this.selected_month_index = 0
                                const chartWrap = this.$el.querySelector('#chartWrap')
                                chartWrap.scroll(90000, 0)
                            })
        },
        setSelectedIncome (income) {
            this.selected_income = income
            this.getIncomesByTag(income.id)
        },
        getIncomesByTag (tagId) {
            const response = this.getServerData('get_incomes_by_tag', [tagId, this.date_from, this.date_to])
            response.then(response => {
                            this.selected_income_data = response.data.items
                                // this.meta = response.data.meta
                            })
                            .catch(function (error) {
                                // handle error
                                console.log(error)
                            })
                            .finally(() => {
                                const chartWrap = this.$el.querySelector('#incomesChartWrap')
                                chartWrap.scroll(90000, 0)
                            })
        },
        setSelectedExpense (expense) {
            this.selected_expense = expense
            this.getExpensesByTag(expense.id)
        },
        getExpensesByTag (tagId) {
            const response = this.getServerData('get_expenses_by_tag', [tagId, this.date_from, this.date_to])
            response.then(response => {
                            this.selected_expense_data = response.data.items
                                // this.meta = response.data.meta
                            })
                            .catch(function (error) {
                                // handle error
                                console.log(error)
                            })
                            .finally(() => {
                                const chartWrap = this.$el.querySelector('#expensesChartWrap')
                                chartWrap.scroll(90000, 0)
                            })
        }
    },
    computed: {
        date_to_local () {
            if (this.date_to == null) return null
            const dateTo = this.date_to.split('-')
            let resultStr = dateTo[2] + '.' + dateTo[1]
            if (this.cdate[0] !== dateTo[0]) resultStr += '.' + dateTo[0].substring(2)
            return resultStr
        },
        date_from_local () {
            if (this.date_from == null) return null
            const dateFrom = this.date_from.split('-')
            let resultStr = dateFrom[2] + '.' + dateFrom[1]
            if (this.cdate[0] !== dateFrom[0]) resultStr += '.' + dateFrom[0].substring(2)
            return resultStr
        },
        net_balance_diff () {
            let diff = 0
            if (this.incomes?.summ?.raw != null && this.expenses?.summ?.raw != null) {
                const incomesNumb = this.incomes.summ.raw
                const expensesNumb = this.expenses.summ.raw
                diff = incomesNumb - expensesNumb
            }
            return diff
        },
        net_balance () {
            var result = new Intl.NumberFormat('fr-FR').format(this.net_balance_diff)
            if (this.net_balance_diff > 0) result = '+' + result
            return result
        },
        net_balance_percent () {
            let nbp = 0
            if (this.incomes?.summ != null) {
                nbp = this.net_balance_diff * 100 / this.incomes.summ.raw
            }
            return nbp
        },
        expenses_circle_plot () {
            var data = []
            var operationals = 0
            var others = 0
            if (this.expenses?.tags) {
                this.expenses.tags.forEach(tag => {
                    if (tag.is_operational === '1') {
                        operationals += parseInt(tag.summ.replaceAll(' ', ''))
                    } else {
                        others += parseInt(tag.summ.replaceAll(' ', ''))
                    }
                })
                data = [operationals, others]
            }
            return data
        },
        incomes_circle_plot () {
            var data = []
            var operationals = 0
            var others = 0
            if (this.incomes?.tags) {
                this.incomes.tags.forEach(tag => {
                    if (tag.is_passive === '1') {
                        operationals += parseInt(tag.summ.replaceAll(' ', ''))
                    } else {
                        others += parseInt(tag.summ.replaceAll(' ', ''))
                    }
                })
                data = [operationals, others]
            }
            return data
        },
        plotHeight () {
            if (this.windowWidth > 900) return 140
            return 90
        }
    },
    mounted () {
        const now = new Date()
        const cdate = new Date(now.getTime() + 360 * 60000).toJSON().slice(0, 10)
        this.cdate = cdate.split('-')
        this.date_from = this.cdate[0] + '-' + this.cdate[1] + '-01'
        this.date_to = this.cdate[0] + '-' + this.cdate[1] + '-' + this.cdate[2]
        this.getDataYear()
        this.getData()
    }
}
</script>
<style scoped>
    .menu_open{
        height: 100%
    }
</style>
